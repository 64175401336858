import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
//import TopBar from 'containers/HostingModern/TopBar';
import Navbar from 'containers/AppModern/Navbar';
//import Navbar from 'containers/AppMinimal/Navbar';
//import Banner from 'containers/AppModern/Banner';
import BannerSection from 'containers/SassMinimal/BannerSection';
import KeyFeatures from 'containers/AppClassic/KeyFeatures';
//import RideOption from 'containers/Ride/RideOption';
//import KeyFeatures2 from 'containers/AppCreative/KeyFeatures';
//import ChooseUs from 'containers/AppCreative/ChooseUs';
//import Experiences from 'containers/AppCreative/Experience';

//import WalletExperience from 'containers/AppMinimal/WalletExperience';

////CSAK APPHOZ, COCCO PL import AppSlider from 'containers/AppClassic/AppSlider';
//import Features from 'containers/AppClassic/Features';
//import DesignedAndBuilt from 'containers/AppClassic/DesignedAndBuilt';
//import AppSlider from 'containers/AppClassic/AppSlider';
import MilestoneBlock from 'containers/Charity/MilestoneBlock';
import FeatureTwoSection from 'containers/SassMinimal/FeatureTwoSection';
//import FeatureSection from 'containers/SassMinimal/FeatureSection';
//import FeatureTwoSection from 'containers/SassMinimal/FeatureTwoSection';
import ServiceSection from 'containers/SassMinimal/ServiceSection';
//import WalletDashboard from 'containers/AppMinimal/WalletDashboard';
//////////////import FeatureTab from 'containers/AppClassic/FeatureTab';
///////////////////////////////////////////////////////kelll import UpdateScreen from 'containers/SaasClassic/UpdateScreen';
//import Gallery from 'containers/AppMinimal/Gallery';
import WalletDashboard from 'containers/AppMinimal/WalletDashboard';
import WalletSection from 'containers/CryptoModern/WalletSection';
//import FundraiserSection from 'containers/Charity/FundraiserSection';
//import KeyFeatures from 'containers/AppCreative/KeyFeatures';
//import TrackAudience from 'containers/SaasMinimal2/TrackAudience';
/////////////////////////////import Statistics from 'containers/SaasMinimal2/Statistics';KELLLLLLLLLLLLLLLLL
//import AdvancedAnalytics from 'containers/SaasMinimal2/AdvancedAnalytics';
//import BannerSlider from 'containers/Crypto/BannerSlider';
//import FeatureSlider from 'containers/AppCreative/FeatureSlider';
//import Dashboard from 'containers/SaasMinimal2/Dashboard';
//////////////////////////////////////////////////////////////////////////import UpdateScreen from 'containers/SaasModern/UpdateScreen';
//import ProcessSection from 'containers/Portfolio/Process';
//import Pricing from 'containers/AppMinimal/Pricing';
///////////////////////////////////////////////////////////////////////import TwitterSection from 'containers/SassMinimal/TwitterSection';

//import DonateSection from 'containers/Charity/DonateSection';

import FundraiserSection from 'containers/Charity/FundraiserSection';

///////import Blog from 'containers/AppMinimal/Blog';
// kesobb import ClientsSection from 'containers/Portfolio/Clients';
//import PricingPolicy from 'containers/AppClassic/PricingPolicy';
//import Testimonial from 'containers/AppClassic/Testimonial';
//import Faq from 'containers/AppClassic/Faq';
//import JoinTrail from 'containers/AppClassic/JoinTrail';
//import Footer from 'containers/AppClassic/Footer';
import BetaSections from 'containers/Crypto/BetaSection';
////import CountDown from 'containers/CryptoModern/CountDown';

import Subscription from 'containers/SaasMinimal2/Subscription';
import Footer from 'containers/AppMinimal/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from 'components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="PayCare Crypto Wallet / exchange / Investment" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
	<BannerSection />

<KeyFeatures />
	<MilestoneBlock />
<FeatureTwoSection />


<FundraiserSection />

<ServiceSection />
<WalletSection />
<WalletDashboard />





     

	






      
					  <BetaSections />
				
								
		  <Subscription />
          </ContentWrapper>

          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
